<template>
  <div class="acontainer">
    <div class="ad-bitmarketpulse">
      <div class="atitle-bitmarketpulse">
        <a href="https://bitmarketpulse.com" title="BITMARKETPULSE">
          BITMARKETPULSE</a>
      </div>
      <div class="alogo"><a href="https://bitmarketpulse.com" title="BITMARKETPULSE">
        <img src="../assets/ads/bitmarketpulse.png" width="100" height="100" alt="BITMARKETPULSE"></a>
      </div>
      <div class="asubtitle-bitmarketpulse">
        <a class="asubtitle-bitmarketpulse" href="https://bitmarketpulse.com" title="BITMARKETPULSE">
          Real time news from our community
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CAdBitMarketPulse'
}
</script>

<style lang="scss">
.acontainer {
  margin-bottom: 15px;
  margin-top: 15px;
}

.ad-bitmarketpulse {
  border: 1px solid #340561;
  max-width: 220px;
  margin: auto;
}

.alogo {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.acenter {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.atitle-bitmarketpulse {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.atitle-bitmarketpulse a {
  color: #340561;
}

.asubtitle-bitmarketpulse {
  text-align: center;
  margin: 0.5rem 0.2rem;
}

.asubtitle-bitmarketpulse a {
  font-size: 0.9em;
  color: #340561;
}

</style>
