<template>
  <l-default>
    <OTitle>Pricing</OTitle>
    <CAdNewsletterLong v-if="!isLoggedIn" />
    <CShareThisInline v-if="isLoggedIn"  />
    <ul class="c-pricing__services">
      <li>Outbound calls: <em>10 credits</em> per minute.</li>
      <li>Voice changer: <em>10 credits</em> per minute.</li>
      <li>Call recording: <em>10 credits</em> per minute.</li>
      <li>New spoof number: <em>15 credits</em>, charged also if rejected.</li>
    </ul>
    <table class="c-pricing">
      <!-- credits to be left aligned -->
      <tr class="c-pricing__item" v-for="pricepoint in pricepoints" v-bind:key="pricepoint.credits">
        <td class="c-pricing__cost"><span class="c-pricing__cost__price">${{ pricepoint.cost }}</span>,</td>
        <td class="c-pricing__credits">{{ pricepoint.credits }} credits</td>
      </tr>
    </table>

    <CAdBitMarketPulse/>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import { mapGetters } from 'vuex'
import CAdNewsletterLong from '@/components/CAdNewsletterLong.vue'
import CShareThisInline from '@/components/CShareThisInline.vue'
import CAdBitMarketPulse from '@/components/CAdBitMarketPulse.vue'

const getApi = () => import('@/services/api')

export default {
  name: 'PagePricing',
  components: { CAdBitMarketPulse, CShareThisInline, CAdNewsletterLong, OTitle, LDefault },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Pricing',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Pricing'
      },
      {
        name: 'keywords',
        content: 'myphonerobot pricing'
      }
    ]
  },
  data: () => ({ loading: true, pricepoints: [] }),
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    async load () {
      const api = await getApi()
      const result = await api.getPricePoints({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword
      })
      if (result.error) {
        this.error = result.message
        return false
      }
      this.pricepoints = result.price_points
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style lang="scss">
.c-pricing,
.c-pricing__item,
.c-pricing__cost,
.c-pricing__credits {
  border: none;
}

.c-pricing {
  border-collapse: collapse;
  align-self: flex-start;
}

.c-pricing__item:before {
  content: "";
  display: list-item;
  list-style-type: circle;
  margin-left: 1.25rem;
}

.c-pricing__cost {
}

.c-pricing__cost__price {
  color: $primary;
}

.c-pricing__credits {
  color: black;
}

.c-pricing__services {
  padding: 20px;
}

</style>
